import { parameterExtraction, docWithSeo } from '@kaliber/sanity-routing/sanity'

import { snippets } from '/groqSnippets'
import { wordCount, dateWithoutDashes } from '/machinery/tracking/metadata'

const { extract, language, slug } = parameterExtraction

export function getRouteData() {
  return {
    app: {
      data: {
        groq: ({ params: { language } }) => ({
          settings: snippets.settings(),
          headerMenu: snippets.landingHeaderMenu({ language }),
          footerData: snippets.landingFooter({ language })
        }),
        derived: ({ data, params }) => ({
          dataLayer: {
            functionality_storage: 'granted',
            analytics_storage: 'granted',
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            personalization_storage: 'denied',
            security_storage: 'granted',
            metadata: {
              content: {
                language: params.language,
                ...(data.doc?._id && { id: data.doc?._id })
              }
            }
          }
        }),
      },

      home: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument('landing', snippets.projections.landing, { language })
          }),
          fetch: {
            normalizedContent: requestHandlers => requestHandlers.normalizeContent
          },
          derived: ({ data, derived }) => deriveDocumentInformation({ data: withNormalizedContent(data), derived }, {
            wordCount,
            templateType: 'landing',
            type: 'article',
          }),
          extractParams: { landing: extract(language, slug) }
        }
      },

      pageLanding: {
        data: {
          groq: ({ params: { language, slug } }) => ({
            doc: snippets.document('pageLanding', snippets.projections.pageLanding, { slug, language })
          }),
          fetch: {
            normalizedContent: requestHandlers => requestHandlers.normalizeContent
          },
          derived: ({ data, derived }) => deriveDocumentInformation({ data: withNormalizedContent(data), derived }, {
            shareImage: x => x.heroImage,
            wordCount,
            templateType: 'content-page-landing',
            type: 'article',
          }),
          extractParams: { pageLanding: extract(language, slug) }
        },
      },

      landingCookiePolicy: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument('landingCookiePolicy', snippets.projections.landingCookiePolicy, { language }),
          }),
          extractParams: { landingCookiePolicy: extract(language) },
          derived: ({ data, derived }) => deriveDocumentInformation({ data, derived }, {
            wordCount,
            templateType: 'cookie-policy-landing',
            type: 'article'
          })
        },
      },
    },
  }
}

function deriveDocumentInformation({ data, derived = { dataLayer: {} } },
  {
    title = x => x.title,
    description = _ => undefined,
    shareImage = _ => data.settings?.shareImage,
    wordCount = _ => undefined,
    templateType = undefined,
    type = undefined,
    canonicalUrl = undefined,
  } = {}
) {
  const { doc } = data

  if (!doc) return

  const dateUpdatedValue = doc?._updatedAt ? dateWithoutDashes(doc._updatedAt) : undefined
  const dateCreatedValue = doc?._createdAt ? dateWithoutDashes(doc._createdAt) : undefined
  const wordCountValue = wordCount(doc?.content ?? [])

  return {
    doc: docWithSeo(doc, {
      title: title(doc),
      description: description(doc),
      social: {
        shareImage: shareImage(doc)
      },
      ...(canonicalUrl && { advanced: { canonicalUrl } }),
    }),
    dataLayer: {
      ...derived.dataLayer,
      metadata: {
        ...derived.dataLayer?.metadata,
        template: {
          type: templateType
        },
        content: {
          ...derived.dataLayer?.metadata?.content,
          title: title(doc),
          ...(dateUpdatedValue && { dateupdated: dateUpdatedValue }),
          ...(dateCreatedValue && { datecreated: dateCreatedValue }),
          ...(wordCountValue && { wordcount: wordCountValue }),
          type,
        }
      }
    }
  }
}

function withNormalizedContent(data) {
  if (!data.doc) return data

  return {
    ...data,
    doc: {
      ...data.doc,
      content: data.normalizedContent
    }
  }
}
