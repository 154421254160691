import groq from 'groq'

const linkableDocumentFields = groq`_type, slug, language, _id`
const linkableDocumentObject = groq`{${linkableDocumentFields}}`

const linkWithLabelItem = groq`{
  _type == 'internalLink' => { _type, ref->${linkableDocumentObject}, label },
  _type == 'externalLink' => { _type, href, label },
  _type == 'jobsOverviewLink' => { 'ref': *[_type == 'jobsOverview' && language == $language][0], _type, filters{ sector[], expertise[], label[], experienceLevel[] }, label },
}`

const imageObject = groq`{alt, crop, hotspot, asset->{_id, url, extension, size, metadata{dimensions}}}`

const contentObject = groq`{
  ...,
  _type == 'cta' => ${ctaProjection()},
  _type == 'ctaSmall' => ${ctaSmallProjection()},
  _type == 'quote' => {quote[], 'sourceObject': sourceArray[0]{ source, role, image${imageObject} }},
  _type == 'video' => ${videoProjection()},
  _type == 'numberInfoSlider' => ${numberInfoSliderProjection()},
  _type == 'faqWithThemeOptions' => ${faqProjection()},
  _type == 'featuredLabels' => {title, description, items[] -> ${featuredLabelsRefProjection()}},
  _type == 'featuredBenefits' => {title, description, items[] -> ${featuredBenefitsRefProjection()}},
  _type == 'recruiter' => {recruiter -> { name, image, phone, email, linkedinUrl }, theme},
  _type == 'featuredServices' => {title, description, items[] -> ${featuredServicesRefProjection()}},
  _type == 'featuredClients' => {title, description, items[] -> ${featuredClientsRefProjection()}},
  _type == 'featuredCases' => {title, description, items[] -> ${featuredCasesRefProjection()}},
  markDefs[] {
    ...,
    _type == 'internalLink' => { ref->${linkableDocumentObject} }
  }
}`

const defaultFields = `_id, _type, _createdAt, _updatedAt, seo, language`

export const snippets = {
  projections: {
    landing: groq`{
      ${defaultFields},
      title,
      heroImage${imageObject},
      introduction,
      content[] ${contentObject},
      faq${faqProjection()},
    }`,
    pageLanding: groq`{
      ${defaultFields},
      slug,
      title,
      heroImage${imageObject},
      introduction,
      content[]${contentObject},
    }`,
    landingCookiePolicy: groq`{
      ${defaultFields},
      title,
      content[]${contentObject}
    }`
  },

  settings() {
    return groq`*[_id == 'settings'] | order(_updatedAt desc) [0] {shareImage, organisation}`
  },

  landingHeaderMenu({ language }) {
    return [
      groq`*[_type == 'landingHeaderMenu' && language == $language] | order(_updatedAt desc) [0] {
        menu[]->${featuredLabelsRefProjection()}
      }`,
      { language },
    ]
  },

  landingFooter({ language }) {
    return [
      groq`*[_type == 'landingFooter' && language == $language] | order(_updatedAt desc) [0] {
        menu[]${linkWithLabelItem},
        labels[] -> {logo${imageObject}}
      }`,
      { language }
    ]
  },

  singletonDocument(type, projection, { language }) {
    return [
      groq`
          *[_type == $type && language == $language] |
          order(_updatedAt desc) [0] ${projection}
        `,
      { type, language }
    ]
  },

  document(type, projection, { language, slug }) {
    return [
      groq`
        *[_type == $type && language == $language && slug.current == $slug] |
        order(_updatedAt desc) [0] ${projection}
      `,
      { type, language, slug }
    ]
  },

  documents(type, projection, { language }) {
    return [
      groq`*[_type == $type && language == $language] ${projection}`,
      { type, language }
    ]
  },

  expertises({ language }) {
    return [
      groq`*[_type == 'expertise' && language == $language] | order(_updatedAt desc) { title, slug }`,
      { language }
    ]
  },

  sectors({ language }) {
    return [
      groq`*[_type == 'sector' && language == $language] | order(_updatedAt desc) { title, slug }`,
      { language }
    ]
  },

  latestStories({ language }) {
    return [
      groq`*[_type == 'story' && language == $language] | order(_updatedAt desc) [0..3] ${featuredStoriesRefProjection()}`,
      { language }
    ]
  },
}

function ctaFields() {
  return groq`
    title,
    'link': link[0]${linkWithLabelItem},
    theme,
  `
}

function ctaProjection() {
  return groq`{
    ${ctaFields()}
    size,
    description,
    image${imageObject}
  }`
}

function ctaSmallProjection() {
  return groq`{${ctaFields()}}`
}

function numberInfoSliderProjection() {
  return groq`{
    items[]{number, description},
    theme
  }`
}

function videoProjection() {
  return groq`{
    src,
    image${imageObject}
  }`
}

function faqProjection() {
  return groq`{
    title,
    theme,
    items[] {
      title,
      content[]{
        ...,
        markDefs[] {
          ...,
          _type == 'internalLink' => { ref->${linkableDocumentObject} }
        }
      }
    }
  }`
}

function featuredStoriesRefProjection() {
  return groq`{
    ${linkableDocumentFields},
    _id,
    shortTitle,
    introduction,
    'image': heroImage${imageObject}
  }`
}

function featuredBenefitsRefProjection() {
  return groq`{
    _id,
    title,
    description,
    'illustration': illustration->illustration
  }`
}

function featuredLabelsRefProjection() {
  return groq`{
    title,
    corporateLink,
    brandColors{ backgroundColor, textColor },
    logo{ asset -> {url} },
    expertises[] -> { title },
    sectors[] -> { title }
  }`
}

function featuredServicesRefProjection() {
  return groq`{
    _id,
    title,
    description,
    'illustration': illustration -> illustration
  }`
}

function featuredClientsRefProjection() {
  return groq`{
    _id,
    title,
    url,
    logo{ asset -> {url}}
  }`
}

function featuredCasesRefProjection() {
  return groq`{
    _id,
    title,
    url,
    tags[],
    image${imageObject}
  }`
}
