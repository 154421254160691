import { asRouteMap } from '@kaliber/routing'

import { getRouteData } from './routeData'

const routeData = getRouteData()

export const routeMap = asRouteMap(
  {
    root: '',
    api: {
      path: 'api',
      v1: {
        path: 'v1',
        url: {
          path: 'document/path',
          data: requestHandlers => requestHandlers.determineDocumentPath,
        },
      },
      sitemap: {
        path: 'sitemap',
        data: requestHandlers => requestHandlers.sitemap,
      },

      notFound: '*'
    },
    preview: 'preview',
    app: {
      path: ':language',
      data: routeData.app.data,
      home: {
        path: '',
        data: routeData.app.home.data
      },
      pageLanding: {
        path: ':slug',
        data: routeData.app.pageLanding.data,
      },
      landingCookiePolicy: {
        path: {
          en: 'cookie-policy',
          nl: 'cookie-verklaring'
        },
        data: routeData.app.landingCookiePolicy.data,
      },
      notFound: '*',
    },
    admin: 'admin'
  },
  { trailingSlash: true }
)
